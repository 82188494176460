import { GetServerSideProps } from "next";

import PageContent, { PageProps } from "components/cms/PageContent";

import wagtail from "lib/api/wagtail";

import { setCachingPolicy } from "services/caching";

import Custom404Page from "./404";

const Page = ({ page }: PageProps) => {
  if (!page) {
    return <Custom404Page />;
  }
  return <PageContent page={page} />;
};

export default Page;

type Params = {
  slug: string;
};

export const getServerSideProps: GetServerSideProps = async ({ params, res }) => {
  const { slug } = params as Params;
  const page = await wagtail.getPageBySlug(slug);

  if (page) {
    setCachingPolicy(res);
    return { props: { page } };
  }

  return {
    props: {},
  };
};
